.btngroup {
  display: flex;
  margin: 10px 0 15px;

  &__btn {
    background-color: #fff;
    padding: 10px 24px;
    font-size: 14px;
    border-radius: 0;
    border: 1px solid #ccc;
    margin-left: -1px;

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }

    &--active {
      background-color: var(--brand-color);
      color: var(--primary-text-color);
    }
  }
}

.sub {
  border: 1px solid hsla(var(--primary-text-color_hsl), 0.5);
  padding: 20px;
  position: relative;
  border-radius: 4px;

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 4px;
  }

  &__amount {
    font-size: 2rem;
    line-height: normal;
    margin: 4px 0 12px;
  }

  &__billing {
    font-size: 14px;
  }

  &__button {
    margin-top: 20px;
  }

  /* Status indicator */
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 15px;
    right: 13px;
    border-radius: 100%;
    background-color: transparent;
    height: 13px;
    width: 13px;
  }

  &--trialing,
  &--active {
    &::before {
      background-color: #0f0;
    }
  }

  &--cancelling::before {
    background-color: #ff0;
  }

  &--incomplete,
  &--incomplete_expired,
  &--past_due,
  &--canceled,
  &--unpaid {
    &::before {
      background-color: #f00;
    }
  }
}

.subs {
  .sub {
    border-radius: 0;

    & + .sub {
      border-top: 0;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.dollarfield {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 15px 0;

  &__symbol {
    font-size: 40px;
    display: block;
    line-height: normal;
    margin-right: 6px;
    transform: translateY(-4px);
  }

  &__price {
    font-size: 60px;
    background: transparent;
    border: 0;
    margin: 0;
    color: var(--primary-text-color);
    appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:invalid {
      border: 0;
      box-shadow: none;
    }
  }
}

.payform {
  &__btn {
    margin: 10px 0;
  }
}

body.admin {
  p.whisper {
    max-width: 375px;
    margin: 30px 0;
    font-style: italic;
    font-size: 1.4rem;
    padding-left: 20px;
  }

  p.btnlabel {
    margin: 8px 0;
    font-weight: bold;
    font-size: 14px;
  }

  .actions {
    margin-top: 40px;
  }
}

.funding-panel {
  margin-top: 15px;

  strong {
    font-weight: bold;
  }

  &__ratio {
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__description {
    margin-top: 14px;
    font-size: 15px;
  }

  .button {
    margin-top: 20px;
  }

  &__reached {
    color: var(--brand-color);
  }

  .wtf-panel__content {
    box-sizing: border-box;
    padding: 15px;
  }
}

.progress-bar {
  height: 8px;
  width: 100%;
  border-radius: 4px;
  background: var(--background-color);
  overflow: hidden;

  &__progress {
    height: 100%;
    background: var(--brand-color);
  }
}
