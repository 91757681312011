.search {
  position: relative;
}

input.search__input {
  @include search-input;
  display: block;
  padding: 7px 30px 6px 10px;
}

.search__icon {
  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus {
    outline: 0 !important;
  }

  .svg-icon {
    @include font-size(16);
    cursor: default;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    z-index: 2;
    width: 18px;
    height: 18px;
    color: var(--primary-text-color--faint);
    opacity: 0;
    pointer-events: none;

    &.active {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .svg-icon--search.active {
    pointer-events: none;
  }

  .svg-icon--backspace {
    cursor: pointer;
    color: var(--highlight-text-color);
    width: 22px;
    height: 22px;

    &:hover {
      color: var(--brand-color);
    }
  }
}

.search-results__header {
  color: var(--primary-text-color);
  background: var(--accent-color--med);
  padding: 15px;
  font-weight: 500;
  font-size: 16px;
  cursor: default;

  .fa {
    display: inline-block;
    margin-right: 5px;
  }
}

.search-results__section {
  h5 {
    background: var(--accent-color--faint);
    border-bottom: 1px solid var(--brand-color--faint);
    cursor: default;
    display: flex;
    padding: 15px;
    font-weight: 500;
    font-size: 16px;
    color: var(--primary-text-color);

    .fa {
      display: inline-block;
      margin-right: 5px;
    }
  }

  &:not(.has-more) .account:last-child,
  &:not(.has-more) > div:last-child .status {
    border-bottom: 0;
  }
}

.search-results__hashtag {
  display: block;
  padding: 10px;
  color: var(--primary-text-color--faint);
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: var(--primary-text-color);
    text-decoration: underline;
  }
}

.search-header {
  display: block;
  width: 100%;

  &__text-container {
    display: none;
    padding: 25px 0;
    background-color: var(--accent-color--med);

    @media (min-width: 896px) {
      display: block;
    }
  }

  &__title-text {
    color: var(--primary-text-color);
    font-size: 27px;
    font-weight: bold;
    line-height: 32px;
    overflow: hidden;
    padding-left: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__type-filters-tabs {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @media screen and (max-width: 895px) {
      max-width: 580px;
    }
  }

  @media (min-width: 896px) and (max-width: 1190px) {
    &__title-text,
    &__type-filters-tabs {
      max-width: 900px;
    }
  }
}

.search-popout-container {
  width: 351px;
  @media screen and (max-width: $nav-breakpoint-2) { width: 100%; }
}

.search-popout {
  @include search-popout;
}

.search__filter-bar:last-child {
  border-bottom: none;
}

.column {
  .search {
    padding: 10px 15px;
    background-color: var(--foreground-color);
    border-bottom: 1px solid hsla(var(--primary-text-color_hsl), 0.2);
  }

  input.search__input {
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 12px 36px 12px 16px;
  }

  .search__icon .svg-icon {
    right: 24px;
  }
}

.search-results {
  padding: 15px;
}

.aliases_search,
.list-editor__search {
  .search__icon {
    position: relative;

    .svg-icon {
      right: 0;
    }
  }
}

.search .autosuggest-textarea__suggestions {
  border-radius: 4px;
}
