.developers-challenge {
  .code {
    font-family: 'Roboto Mono', monospace;
    cursor: text;
    background-color: var(--background-color);
  }

  span.code {
    padding: 2px 4px;
    border-radius: 4px;
  }

  pre.code {
    line-height: 1.6em;
    overflow-x: auto;
    border-radius: 6px;
    padding: 8px 12px;
    margin: 20px 0;
    word-break: break-all;
  }
}
