.display-name__account {
  position: relative;
}

.display-name .profile-hover-card {
  white-space: normal;
}

.profile-hover-card {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.2s;
  width: 320px;
  z-index: 200;
  top: 0;
  left: 0;

  &--visible {
    opacity: 1;
    pointer-events: all;
  }

  .profile-hover-card__container {
    @include standard-panel;
    position: relative;
    overflow: hidden;
  }

  .profile-hover-card__action-button {
    z-index: 999;
    position: absolute;
    right: 20px;
    top: 40px;
  }

  .user-panel {
    box-shadow: none;
    width: auto;

    .user-panel-stats-item a strong {
      text-decoration: none;
    }

    &__header {
      height: 96px;
    }

    .user-panel-stats-item {
      margin-right: 10px;

      &__label,
      &__value {
        display: inline;
        font-size: 15px;
      }

      &__value {
        margin-right: 5px;
      }
    }
  }

  .relationship-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
  }

  .profile-hover-card__badges {
    display: flex;
    position: absolute;
    top: 110px;
    left: 120px;

    .badge {
      padding: 2px 4px;
      margin-right: 5px;
      border-radius: 3px;
      font-size: 11px;
      opacity: 1;
    }
  }

  .profile-hover-card__bio {
    margin: 0 20px 20px;
    max-height: 4em;

    a {
      color: var(--highlight-text-color);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 20px;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, var(--foreground-color) 0%, var(--foreground-color), 80%, transparent);
    }
  }

  &[data-popper-reference-hidden="true"] {
    visibility: hidden;
    pointer-events: none;
  }
}
