.emoji-react {
  display: inline-block;
  transition: 0.1s;
  color: var(--primary-text-color--faint);
  text-decoration: none;

  &__emoji {
    img {
      width: 20px;
      height: 20px;
      filter: drop-shadow(2px 0 0 var(--foreground-color));
    }
  }

  &__count {
    display: none;
  }

  + .emoji-react {
    margin-right: -8px;
  }

  &[type='button'] {
    cursor: pointer;
  }
}

.emoji-react--reblogs,
.emoji-react--favourites {
  vertical-align: middle;
  display: inline-flex;
  margin-right: 10px;

  .svg-icon {
    margin-right: 0.2em;
  }
}

.emoji-react--reblogs {
  .svg-icon {
    color: var(--highlight-text-color);

    svg {
      stroke-width: 3px;
    }
  }
}

.emoji-react--favourites {
  .svg-icon {
    color: $gold-star;
  }
}

.emoji-reacts {
  display: inline-flex;
  flex-direction: row-reverse;
}

.status-interaction-bar {
  margin-right: auto;
  min-height: 20px;
}

.emoji-reacts-container {
  display: inline-flex;
  margin-right: 0.4em;

  &:hover {
    .emoji-react {
      margin: 0;

      &__count {
        display: inline;
      }
    }

    .emoji-reacts__count {
      display: none;
    }
  }
}

.emoji-reacts__count,
.emoji-react__count {
  font-size: 12px;
  font-weight: bold;
  transform: translateY(2px);
}

.emoji-react-selector-container {
  &:hover,
  &:focus {
    outline: 0;
  }
}

.emoji-react-selector {
  position: absolute;
  display: flex;
  background-color: var(--foreground-color);
  padding: 5px 8px;
  border-radius: 9999px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: 0.1s;
  z-index: 999;

  &--visible,
  &--focused {
    opacity: 1;
    pointer-events: all;
  }

  &__emoji {
    display: block;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;

    img {
      width: 30px;
      height: 30px;
      padding: 3px;
      transition: 0.1s;
    }

    &:hover,
    &:focus {
      img {
        transform: scale(1.2);
      }
    }
  }
}

.status__action-bar__counter--favourite {
  position: relative;

  @media (max-width: 455px) {
    position: static;
  }
}

.status__action-bar,
.detailed-status__action-bar {
  .svg-icon {
    width: 20px;
    height: 20px;
    display: block;
    overflow: visible;
  }

  .icon-button__emoji .emojione {
    width: 20px;
    height: 20px;
    margin: 0;
  }

  svg {
    stroke-width: 2px;
  }

  svg.icon-tabler {
    // Tabler icons are a little thicker than Feather icons
    stroke-width: 1.6px !important;
    transform: scale(1.2);
  }
}

// This icon is a little different than the others...
.status__action-bar__counter--favourite,
.detailed-status__button--favourite {
  .svg-icon svg.icon-tabler {
    transform: translateY(-1px) scale(1.3);
    stroke-width: 1.4px !important;
  }
}

.detailed-status__wrapper .emoji-react-selector {
  bottom: 40px;
  right: 10px;
}

.status .emoji-react-selector {
  bottom: 100%;
  left: -20px;

  @media (max-width: 455px) {
    bottom: 31px;
    right: 10px;
    left: auto;
  }
}
