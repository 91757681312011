.primary-navigation__wrapper {
  .primary-navigation {
    flex-direction: column;

    > button,
    > .btn {
      justify-content: flex-start;
      font-size: 19px;
      font-weight: bold;

      .svg-icon {
        width: 26px;
        height: 26px;
        margin-right: 10px;

        svg.icon-tabler {
          stroke-width: 1.5px;
        }

        &--active {
          svg.icon-tabler-search,
          svg.icon-tabler-code {
            stroke-width: 2.5px;
          }
        }
      }

      &::before {
        left: 0;
        border-radius: 999px;
      }
    }

    &__icon {
      margin-right: 5px;
    }

    .icon-with-counter__counter {
      right: 0;
    }

    hr {
      border: 0;
      height: 1px;
      width: 100%;
      background: hsla(var(--primary-text-color_hsl), 0.12);
      max-width: 230px;
      text-align: left;
      margin: 10px 0;
    }
  }
}

.thumb-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: var(--thumb-navigation-base-height);
  display: flex;
  background: var(--foreground-color);
  justify-content: space-between;
  box-shadow: 0 -1px hsla(var(--primary-text-color_hsl), 0.2);
  border-radius: 0;
  z-index: 1000;
  padding-bottom: env(safe-area-inset-bottom); /* iOS PWA */
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--background-color);

  &::before,
  &::after {
    content: '';
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__link {
    padding: 8px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    color: var(--primary-text-color);
    text-decoration: none;
    font-size: 20px;
    width: 55px;

    span {
      margin-top: 1px;
      text-align: center;
      font-size: 1.2rem;
    }

    .svg-icon {
      width: 24px;
      height: 24px;

      svg {
        stroke-width: 1px;
      }
    }

    .icon-with-counter__counter {
      right: -7px;
      top: -5px;
    }
  }

  @media (min-width: 895px) {
    display: none;
  }
}

.sub-navigation {
  position: sticky;
  top: 50px;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
  display: flex;
  color: var(--primary-text-color--faint);
  background: var(--foreground-color);
  border-bottom: 1px solid hsla(var(--primary-text-color_hsl), 0.2);
  border-radius: 0;
  align-items: center;
  justify-content: center;
  z-index: 999;

  &--scrolled {
    border-radius: 0 !important;
  }

  &__content {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    padding: 5px 15px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
  }

  &__back {
    margin-right: auto;
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-text-color);
    opacity: 0.6;
    font-size: 16px;

    &:hover,
    &:focus {
      color: var(--primary-text-color);
    }

    .svg-icon {
      margin-right: 7px;
      width: 26px;
      height: 26px;
    }
  }

  &__message {
    position: absolute;
    padding: 0 10px;
    align-self: center;
    justify-self: center;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 200px);
  }

  &__cog {
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      width: 20px;
      height: 20px;
    }
  }

  @media (min-width: 580px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
