.notification {
  border-bottom: 1px solid var(--brand-color--med);
  padding: 10px 0;
}

.notification-mention {
  padding: 15px 0;
}

.notification-favourite {
  .status.status-direct {
    background: transparent;

    .icon-button.disabled {
      color: hsla(var(--brand-color_hsl), 0.2);
    }
  }
}

.notification__message {
  margin: 0 10px 0 68px;
  padding: 8px 0 0;
  cursor: default;
  color: var(--primary-text-color--faint);
  font-size: 15px;
  line-height: 22px;
  position: relative;

  .fa {
    color: var(--highlight-text-color);
  }

  > span {
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.notification__icon-wrapper {
  left: -26px;
  position: absolute;

  .svg-icon {
    width: 20px;
    height: 20px;
    color: var(--highlight-text-color);

    svg.icon-tabler-thumb-up {
      color: $gold-star;
    }

    svg.feather-repeat {
      color: var(--highlight-text-color);
    }
  }
}

.notification__display-name {
  color: inherit;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: var(--primary-text-color);
    text-decoration: underline;
  }
}

.notification__relative_time {
  float: right;
}

.notification .status__wrapper {
  box-shadow: none;
}

.notification {
  .status-container {
    padding: 0;
  }

  .status__wrapper {
    border-radius: 0;
    padding: 0;
  }

  .status {
    padding-bottom: 8px !important;
  }
}

.notification-birthday span[type="button"] {
  &:focus,
  &:hover,
  &:active {
    text-decoration: underline;
    cursor: pointer;
  }
}

.columns-area .notification-birthday {
  .notification__message {
    padding-top: 0;
  }
}
