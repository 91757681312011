.profile-info-panel {
  display: block;
  position: relative;

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1;

    @media (min-width: 896px) {
      padding-top: 60px;
    }
  }

  .profile-info-panel-content {
    display: flex;

    &__badges {
      display: flex;
      margin: 5px 0;
      flex-direction: row;
      flex-wrap: wrap;
    }

    &__join-date,
    &__birthday,
    &__location,
    &__note {
      display: flex;
      font-size: 14px;
      color: var(--primary-text-color--faint);
      align-items: center;
      line-height: normal;
      margin-top: 15px;

      .svg-icon {
        width: 22px;
        height: 22px;
        margin-right: 5px;
        transform: translateY(-1px);
      }
    }

    &__note {
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }

    &__stats {
      margin: 15px 0;

      .profile-stat {
        font-size: 16px;
      }
    }

    &__name {
      display: block;

      .emojione {
        width: 22px;
        height: 22px;
      }

      .svg-icon {
        width: 20px;
        height: 20px;
        margin-left: 3px;
      }

      h1 {
        span:first-of-type {
          font-size: 20px !important;
          line-height: 1.25;
          color: var(--primary-text-color);
          font-weight: 600 !important;
          max-width: 260px;
        }

        small {
          display: flex;
          font-size: 15px;
          line-height: 1.5;
          color: var(--primary-text-color--faint);
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__bio {
      display: block;
      flex: 1 1;
      color: var(--primary-text-color);
      margin-top: 15px;
      font-size: 15px;
      line-height: 1.25;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        color: var(--highlight-text-color);
      }
    }

    &__fields {
      display: flex;
      flex-direction: column;
      border-top: 1px solid var(--brand-color--med);
      padding: 10px 0;
      margin: 5px 0;

      a {
        color: var(--highlight-text-color);
      }

      dl:first-child .verified {
        border-radius: 0 4px 0 0;
      }

      .verified a {
        color: $valid-value-color;
      }

      &__item {
        display: block;
        padding: 2px 0;
        margin: 2px 0;
        flex: 1 1;

        * {
          line-height: 24px;
        }

        dt {
          min-width: 26px;
          font-weight: 900;
          font-size: 14px;
        }

        dd {
          padding-left: 8px;
          font-size: 15px;
        }
      }
    }

    &__deactivated {
      display: none;
    }
  }
}

.profile-info-panel.deactivated {
  .profile-info-panel-content__name h1 small,
  .profile-info-panel-content__badges__join-date,
  .profile-info-panel-content__bio,
  .profile-info-panel-content__fields {
    display: none;
  }

  .profile-info-panel__name-content {
    text-transform: uppercase;
  }

  .profile-info-panel-content__deactivated {
    color: var(--primary-text-color--faint);
    display: block;
  }

  .profile-info-panel__name-content::before {
    content: "[";
  }

  .profile-info-panel__name-content::after {
    content: "]";
  }
}
