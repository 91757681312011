.timeline-queue-header {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-evenly;
  height: 30px;
  position: fixed;
  top: 60px;
  margin: 0 auto;
  background-color: var(--brand-color);
  color: #fff;
  border-radius: 100px;
  transition: 150ms ease;
  overflow: hidden;
  padding: 0 10px;
  z-index: 500;

  .sub-navigation ~ & {
    top: calc(60px + 41px);
  }

  .svg-icon {
    margin-right: 5px;
  }

  &.hidden {
    transform: scaleY(0);
    pointer-events: none;

    .timeline-queue-header__label {
      opacity: 0;
    }
  }

  &__btn {
    display: flex;
    line-height: 46px;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
    white-space: nowrap;
    align-items: center;
    justify-content: center;

    span {
      height: 46px;
    }
  }

  &__label {
    transition: 150ms ease;
  }
}
