.setting-toggle {
  display: block;
  line-height: 24px;
}

.setting-toggle__label {
  color: var(--primary-text-color--faint);
  display: inline-block;
  margin-bottom: 14px;
  margin-left: 8px;
  vertical-align: middle;
}
